<template>
  <div class="oportuinityspace">
    <div class="container content">
      <Meta :title="title" :description="description" />
      <LabContent :notiondata="data" lab="oportuinityspace" />
      <BlockContent @notiondata="apidata" :notionpage="NotionPageID" />
      <Contactform
        lab="oportuinityspace"
        contactheadline="zum Opportunity Space"
        team="Prof. Dr. Dirk Müller & Prof. Dr. Volker Looks"
        welcomemsg="Zusammen mit unserer Kollegin Astrid Fustmann unterstützen wir gerne beim Realisieren von Ideen!"
      />
    </div>
  </div>
</template>

<script type="text/babel">
import Meta from "@/components/seo/Meta.vue";
import LabContent from "@/components/notion/LabContent.vue";
import BlockContent from "@/components/notion/BlockContent.vue";
import Contactform from "@/components/Contactform.vue";

export default {
  name: "Opportunity Space",
  components: {
    Meta,
    LabContent,
    BlockContent,
    Contactform,
  },
  data() {
    return {
      NotionPageID: "06c0210b157d47938285a316fb0fdfa2",
      title: "Realisiere deine Idee",
      description:
        "TeStUp - Das StartUp-Village für technologische Produkte der Hochschule Flensburg unterstützt beim realisieren von Ideen.",
      data: [],
    };
  },
  methods: {
    apidata: function (value) {
      this.data = value;
    },
  },
};
</script>

<style lang="scss"></style>
